import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import BackgroundImage from "gatsby-background-image"
import Newsletter from "../components/newsletter"

export default ({ data }) => {
  const backgroundFluidImageStack = [
    data.banner.childImageSharp.fluid,
    `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))`
  ].reverse();

  return (<Layout>
    <SEO
      title="Partner"
      description="Project partners talking about JRC LIVE"
    />


    <BackgroundImage
      Tag="section"
      className="d-flex justify-content-center align-items-center vh-60"
      fluid={backgroundFluidImageStack}
      backgroundColor={`#ffffff`}
    >
      <Container>
        <Row>
          <Col lg="4">
            <h1 className="display-3 text-whitse">Our Partner.</h1>
            <p className="lead text-whitse">
            Project partners talking about JRC LIVE.
            </p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <section className="py-5">
      <Container>
        <Row>
          <Col lg="4">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded mb-4">
              <iframe 
                title="Carsten Sanders (Head of Innovation, BMW AG) talking about JRC LIVE"
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/yOdPnh_KlKw" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>

          <Col lg="4">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded mb-4">
              <iframe 
                title="Horst Leitner (CEO, Hofer KG) talking about JRC LIVE"
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/xkATthb8HVk" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>

          <Col lg="4">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded mb-4">
              <iframe 
                title="Günther Hendorfer (FH OÖ) talking about JRC LIVE"
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/lcXSjJ_p4dE" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>

          <Col lg="4">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded mb-4">
              <iframe 
                title="Martin Gerzabeck (President of CDG) talking about JRC"
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/VtgrMAw34U4" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>
          
          <Col lg="4">
            <div class="embed-responsive embed-responsive-16by9 shadow rounded mb-4">
              <iframe 
                title="Robert de Souza (National University of Singapore) about JRC LIVE"
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/h7TwtwMPt7c" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <Newsletter />
  </Layout>
)}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "partner.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`